* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --bgHeader: #2a0d35;
  --bgBody: #3b014a;
  --btnBorder: #e15bfe;
  --bgBtn: #9222ae;
  --lightPurple: #561267;
}

.wrapper {
  width: 80%;
  margin: 0 auto;
}
