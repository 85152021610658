.footer {
    width: 100%;
    padding: 20px 0px;
    background-color: var(--bgHeader);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
}

.footer__nav {
    justify-content: space-between;
    column-gap: 30px;
}

.footer__mainMenu {
    justify-content: space-between;
    column-gap: 30px !important;
}

.footer__mainMenu a {
    font-size: 14px !important;
}

.footer__social {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 30px;
}

.footer__social {
    list-style: none;
}

.footer__social a {
    color: white;
    text-decoration: none;
    padding: 10px;
    border: 1px solid white;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms;
}

.footer__social a:hover {
    background-color: var(--btnBorder);
    transition: all 500ms;
}

#copy {
    color: white;
    font-size: 18px;
    font-weight: 600;
}


@media screen and (max-width: 992px) {
    .footer__nav {
        flex-direction: column;
        row-gap: 20px;
    }
}
