.header {
    background-color: var(--bgHeader);
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
}



.header__ul {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header__ul ul {
    list-style: none;
    display: flex;
    column-gap: 20px;
}

.header__ul ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    padding: 10px 15px;
    border: 1px solid var(--btnBorder);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 500ms;
}

.header__ul ul li a:hover{
    border-color: white;
    color: var(--btnBorder);
    background-color: rgba(255, 255, 255, 1);
    transition: all 500ms;
}

.header__mobile {
    display: none;
}

.header__mobile a {
    color: white;
    padding: 10px;
    border: 1px solid white;
    border-radius: 50%;
}

.header__mobile a:hover {
    border-color: white;
    color: var(--btnBorder);
    background-color: rgba(255, 255, 255, 1);
    transition: all 500ms;
}



nav {
    display: flex;
    justify-content: space-between;
}

.header__logo p {
    color: white;
    font-size: 30px;
    font-weight: 700;
}

.header__logo p span {
    color: var(--btnBorder);
}


nav .mainMenu {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
}

nav .mainMenu li a {
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 18px;
    font-weight: 500;
    outline: none;
    padding: 10px 15px;
    border: 1px solid var(--btnBorder);
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 500ms;
}

nav .mainMenu li a:hover {
    border-color: white;
    color: var(--btnBorder);
    background-color: rgba(255, 255, 255, 1);
    transition: all 500ms;
    font-weight: 600;
}

nav .openMenu {
    font-size: 2rem;
    margin: 20px;
    display: none;
    cursor: pointer;
    color: white;

    padding: 10px 20px;
    color: white;
    border: 1px solid white;
    border-radius: 50%;
}

.closeMenu {
    display: none;
}








@media screen and (max-width: 992px) {
    /* .header__ul {
        display: none;
    }

    .header__mobile {
        display: flex;
        justify-content: center;
        text-align: center;
    } */

    nav {
        display: flex;
        align-items: center;
    }


    nav .mainMenu {
        height: 100vh;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
        flex-direction: column;
        justify-content: center;
        row-gap: 40px;
        align-items: center;
        background: black;
        transition: top 1s ease;
        display: none;
    }

    nav .mainMenu li a {
        width: 100%;
    }

    nav .mainMenu .closeMenu {
        display: block;
        position: absolute;
        top: 20px;
        right: 20px;
        color: white;
    }
    
    nav .openMenu {
        display: block;
    }

    nav .mainMenu li a:hover {
        border-color: white;
        color: var(--btnBorder);
        background-color: rgba(255, 255, 255, 1);
        transition: all 500ms;
        font-weight: 600;
    }

}