.ourService {
    background-color: var(--bgBody);
    width: 100%;
    color: white;
    padding: 100px 0px;
}

#ourService__h1 {
    text-align: center;
    font-size: 46px;
    padding: 50px 0px;
}

.ourService__cards {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    padding: 50px 0px;
    row-gap: 50px;
}

.ourService__card {    
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: calc((100% / 4) - 60px);
    max-width: 300px;
    min-width: 250px;
    height: 450px;
    border-radius: 30px;
    border: 2px solid var(--btnBorder);
    transition: all 500ms;
}

.ourService__card:hover {
    background-color: var(--lightPurple);
    cursor: pointer;
    box-shadow: 0px 0px 64px 15px rgba(86,18,103,0.7);
    transition: all 500ms;
}

.ourService__card a:nth-child(1) {
    color: white;
    padding: 30px;
    border: 2px solid white;
    border-radius: 50%;
    background-color: var(--bgBtn);
}

.ourService__card h3 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    width: 90%;
}

.ourService__card a:nth-child(3){
    color: white;
    padding: 20px;
    border: 2px solid var(--btnBorder);
    border-radius: 50%;
    transition: all 500ms;
}

.ourService__card a:nth-child(3):hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: all 500ms;
}


